import { getResourceImageUrl } from "actions/networkActions";
import CenteredImageContainer from "components/CenteredImageContainer";
import Price from "components/Price/Price";
import ActivityDuration from "components/molecules/ActivityDuration";
import { FC } from "react";
import { Link } from "react-router-dom";
import browserHistory from "../../../utils/history";
import { buildTestId } from "utils/buildTestId";

export type Props = {
  id: number;
  itineraryId: string;
  title: string;
  shortDescription: string;
  image: string;
  duration: number;
  standardPrice: string;
  isBookable: boolean;
  date: string;
};

const OneSpaWorldItem: FC<Props> = ({
  id,
  itineraryId,
  title,
  shortDescription,
  image,
  duration,
  standardPrice,
  isBookable,
  date,
}) => {
  const spaTreatmentLink = `/spa-treatment/date/${date}/itinerary-id/${itineraryId}/by-id/${id}`;

  return (
    <div
      data-testid={buildTestId("container", "spa treatment item")}
      className="bg-white border border-blue flex flex-wrap flex-grow"
    >
      <div
        data-testid={buildTestId("img", "spa treatment item")}
        className="w-full sm:w-1/3 p-2 sm:p-4"
      >
        <CenteredImageContainer
          alt="spa treatment preview"
          src={getResourceImageUrl(image, {
            width: 400,
            category: "cruiseOneSpaWorld",
          })}
        />
      </div>

      <div className="w-1/2 sm:w-1/3 flex-grow px-2 sm:px-0 pb-4 sm:pt-4 flex flex-col justify-between">
        <div>
          <div className="text-blue underline text-2xl uppercase">
            {isBookable ? (
              <Link to={spaTreatmentLink}>
                <p
                  data-testid={buildTestId("text", "spa treatment item title")}
                >
                  {title}
                </p>
              </Link>
            ) : (
              <p data-testid={buildTestId("text", "spa treatment item title")}>
                {title}
              </p>
            )}
          </div>
          <div
            data-testid={buildTestId("text", "spa treatment item description")}
            className="mt-2"
          >
            {shortDescription}
          </div>
        </div>

        {duration !== 0 && (
          <div>
            <ActivityDuration
              duration={duration}
              dataTest={buildTestId("text", "spa treatment item end time")}
            />
          </div>
        )}
      </div>

      <div className="flex flex-col flex-grow justify-center">
        {isBookable ? (
          <div className="self-end py-4 px-2 sm:px-4 self-center bg-blue-lightest-25 text-center leading-none">
            <Price preText="from" price={standardPrice} />
            {standardPrice && (
              <button
                data-testid={buildTestId(
                  "button",
                  "spa treatment item price available"
                )}
                className="action-button action-button-enabled w-full mt-3"
                onClick={() => browserHistory.push(spaTreatmentLink)}
              >
                BOOK NOW
              </button>
            )}
          </div>
        ) : (
          <div className="max-w-[260px] sm:max-w-sm md:max-w-md xl:max-w-[260px] py-4 px-2 sm:px-4 self-center bg-blue-lightest-25 text-center leading-none">
            <p>We’re sorry, but pre-booking is no longer available.</p>
            <p>
              Please check availability with our friendly crew once you’re
              onboard.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default OneSpaWorldItem;
