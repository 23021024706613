import Price from "components/Price/Price";
import { FC } from "react";
import CartElementBooking from "../CartElementBooking";

type Props = {
  entry: {
    id: string;
    name: string;
    price: string;
    nrAdults: number;
    nrChildren: number;
    time: string;
    sessionType: string;
    serviceProfileId: string;
  };
  date: any;
};
const CartElementTacBooking: FC<Props> = (props) => {
  const entry = props.entry;
  const nrGuests = entry.nrAdults + entry.nrChildren;

  const modalContent = (
    <>
      <div className="text-lg font-bold">
        Issue refund for the following booking
      </div>
      <div className="bg-blue-lightest p-4 mt-4">
        <div className="text-xl">{entry.name}</div>
        <div className="mt-1">
          Table for {nrGuests} {nrGuests > 1 ? "people" : "person"} at{" "}
          {entry.time}
        </div>
      </div>
      <div className="mt-2">
        <Price preText="Refund value" price={entry.price} />
      </div>
    </>
  );

  return (
    <CartElementBooking
      name={entry.name}
      tooltipId={entry.id}
      entrySessionType={entry.sessionType}
      tooltipText={`Price paid for this<br/>booking (total) £${entry.price}`}
      linkTo={{ pathname: "/tac/restaurants", state: { date: props.date } }}
      imageReference={entry.serviceProfileId}
      modalContent={modalContent}
      refundIds={[entry.id]}
    >
      <div>
        Table for {nrGuests} {nrGuests > 1 ? "people" : "person"}
      </div>
      <div className="mt-1">
        at <span className="font-bold">{entry.time}</span>
      </div>
    </CartElementBooking>
  );
};

export default CartElementTacBooking;
