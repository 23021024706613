import { FC, useEffect, useState } from "react";
import Checkbox from "../../Checkbox";
import Price from "../../Price/Price";
import CartElementBooking from "../CartElementBooking";
import useAppSelector from "../../../hooks/useAppSelector";

type Props = {
  excursion: any;
};
const CartExcursionEntryBooking: FC<Props> = ({ excursion }) => {
  const cartElements = useAppSelector((state) => state.cart.excursions);
  const sessionType = useAppSelector(
    (state) => state.authentication.sessionType
  );

  const [passengers, setPassengers] = useState<any>([]);
  const [nrSelected, setNrSelected] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedIds, setSelectedIds] = useState<any[]>([]);

  const handleCheckboxChange = (id: string) => {
    let nrSelected = 0;
    let errorMessage = "";
    let nrAdultsLeftOnBooking = 0;
    let nrKidsLeftOnBooking = 0;
    let selectedIds: any[] = [];

    const updatedPassengers = passengers.map((passenger: any) => {
      if (passenger.id === id) {
        passenger.selected = !passenger.selected;
      }

      if (passenger.selected) {
        nrSelected++;
        selectedIds.push(passenger.cartElementId);
      } else {
        if (passenger.ageType === "Adult") {
          nrAdultsLeftOnBooking++;
        } else {
          nrKidsLeftOnBooking++;
        }
      }
      return passenger;
    });

    if (nrAdultsLeftOnBooking === 0) {
      if (excursionInCartWithOnlyKids()) {
        errorMessage =
          "Booking cancellation is not possible. Adults must accompany children. Please remove the children from the shopping cart for this excursion first.";
      }

      if (nrKidsLeftOnBooking > 0) {
        errorMessage =
          "Booking cancellation is not possible. Adults must accompany children.";
      }
    }

    setPassengers(updatedPassengers);
    setNrSelected(nrSelected);
    setErrorMessage(errorMessage);
    setSelectedIds(selectedIds);
  };

  const excursionInCartWithOnlyKids = () => {
    const itineraryId = excursion.itineraryId;
    const excursionId = excursion.excursionId;

    if (cartElements[itineraryId] && cartElements[itineraryId][excursionId]) {
      for (let passenger of cartElements[itineraryId][excursionId].passengers) {
        if (passenger.ageType === "Adult") {
          return false;
        }
      }

      return true;
    }

    return false;
  };

  const getRefundValue = () => {
    return passengers.reduce((total: number, passenger: any) => {
      if (passenger.selected) {
        return total + passenger.price;
      }
      return total;
    }, 0);
  };

  const tooltipId =
    "booking-" +
    excursion.itineraryId +
    excursion.excursionId +
    excursion.passengers.join("-");

  const modalContent = (
    <>
      <div className="font-bold">
        Issue refund for the following passengers:
      </div>

      <div className="bg-blue-lightest px-4 py-2 mt-4">
        {passengers.map((passenger: any) => (
          <div className="my-2" key={passenger.id}>
            <Checkbox
              label={`${passenger.firstName} ${passenger.lastName}`}
              checked={passenger.selected}
              disabled={sessionType !== passenger.sessionType}
              disabledReason={
                sessionType !== passenger.sessionType
                  ? "Bookings can only be refunded via the same channel they were booked"
                  : ""
              }
              handleCheckboxChange={() => handleCheckboxChange(passenger.id)}
            />
          </div>
        ))}
      </div>

      <Price preText="Refund value" price={getRefundValue()} />
      {errorMessage && <div className="errors mt-2">{errorMessage}</div>}
    </>
  );

  useEffect(() => {
    const updatedPassengers = excursion.passengers.map((passenger: any) => ({
      ...passenger,
      selected: false,
    }));

    setPassengers(updatedPassengers);
    setNrSelected(0);
    setErrorMessage("");
    setSelectedIds([]);
  }, [excursion.passengers]);

  return (
    <div>
      <CartElementBooking
        name={excursion.excursionName}
        tooltipId={tooltipId}
        tooltipText={`Price paid for this<br/>excursion (total) £${excursion.price}`}
        linkTo={`/excursion/${excursion.itineraryId}/${excursion.excursionId}`}
        imageReference={excursion.excursionId}
        imageCategory="cruiseExcursion"
        modalContent={modalContent}
        refundDisabled={nrSelected === 0 || errorMessage}
        refundIds={selectedIds}
      >
        <ul className="list-reset my-2 md:flex-grow">
          {excursion.passengers.map((passenger: any, index: number) => (
            <li
              key={index}
            >{`${passenger.firstName} ${passenger.lastName}`}</li>
          ))}
        </ul>
      </CartElementBooking>
    </div>
  );
};
export default CartExcursionEntryBooking;
