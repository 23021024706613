import { imagePath } from "../utils/imageUtils";
import useAppSelector from "../hooks/useAppSelector";

const linkRiver =
  "https://www.tui.co.uk/destinations/info/river-cruise-with-confidence";
const linkOceanFAQ =
  "https://www.tui.co.uk/destinations/faq/marella-cruises/before-you-go";
const linkOceanForeighnTravelAdvice =
  "https://www.gov.uk/foreign-travel-advice";

const TravelRequirementsMessage = () => {
  const isRiverUrl = useAppSelector((state) => state.appConfig.isRiverUrl);

  if (isRiverUrl) {
    return (
      <div className="ml-3 flex-grow">
        Head over to the{" "}
        <a
          href={linkRiver}
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cruise with Confidence Hub
        </a>{" "}
        detailing what you need to do to prepare to set sail with TUI River
        Cruises, from testing and entry requirements to re-entry requirements
        for the UK.
      </div>
    );
  }

  return (
    <div className="ml-3 flex-grow">
      For any entry requirements for the ports on your cruise please check our{" "}
      <a
        href={linkOceanFAQ}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        FAQ
      </a>{" "}
      and{" "}
      <a
        href={linkOceanForeighnTravelAdvice}
        className="underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        UK Foreign Office Travel Advise.
      </a>
    </div>
  );
};

const TravelRequirementsHeader = () => {
  const showItinerary = useAppSelector(
    (state) => state.location.isItineraryShown
  );
  const showDashboard = useAppSelector(
    (state) => state.location.isDashboardShown
  );

  if (!showDashboard && !showItinerary) {
    return null;
  }

  return (
    <header className="bg-grey-lighter print-hidden">
      <div className="container mx-auto py-4">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img
              src={imagePath("exclamation-circled.svg")}
              alt=""
              className="w-6"
            />
          </div>
          <TravelRequirementsMessage />
        </div>
      </div>
    </header>
  );
};

export default TravelRequirementsHeader;
