import { FC } from "react";
import CartElementCheckout from "../../CartElementCheckout";
import { formatCartElementDate } from "../../../../utils/dateTimeUtils";
import ActivityStartTime from "../../../molecules/ActivityStartTime";
import ActivityDuration from "../../../molecules/ActivityDuration";

type CartElementOswCheckoutProps = {
  itineraryId: string;
  title: string;
  image: string | null;
  scheduledDay: string;
  scheduledTime: string;
  duration: number;
  fullName: string;
  price: number;
  dayNr: number;
  date: string;
};

const CartElementOswCheckout: FC<CartElementOswCheckoutProps> = ({
  itineraryId,
  title,
  image,
  price,
  dayNr,
  scheduledTime,
  scheduledDay,
  duration,
  fullName,
}) => {
  return (
    <CartElementCheckout
      name={title}
      imageReference={image || ""}
      linkTo={`/spa-and-wellness/${itineraryId}`}
      pricePreText="Spa Treatment Price:"
      price={price.toString()}
      dataTest="cartElementOswCheckout"
    >
      <div>
        On day {dayNr} ({formatCartElementDate(scheduledDay)})
      </div>
      <div className="mt-2">
        <ActivityStartTime startTime={scheduledTime} />
      </div>
      {duration !== 0 && (
        <div className="mt-2">
          <ActivityDuration duration={duration} />
        </div>
      )}
      <div className="mt-2">
        Name: <span className="font-bold">{fullName}</span>
      </div>
    </CartElementCheckout>
  );
};

export default CartElementOswCheckout;
