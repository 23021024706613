import React from "react";
import useAppSelector from "hooks/useAppSelector";
import CartElementItineraryMusement from "../CartElementItineraryMusement";
import CartElementPaidActivityMusement from "../CartElementPaidActivityMusement";
import { IExcursionPassenger } from "interfaces/Common/IPassenger";
import { getCombinedMusementEntryByActivityUuid } from "modules/musement/utils/getCombinedMusementEntryByActivityUuid";
import { getMusementItemUuidsByPersonType } from "modules/musement/utils/getMusementItemUuidsByPersonType";
import { getMusementItemIdsByPersonType } from "modules/musement/utils/getMusementItemIdsByPersonType";

type Props = {
  date: string;
};

const CartElementsMusement: React.FC<Props> = ({ date }) => {
  const allMusementActivitiesInCart = useAppSelector(
    (state) => state.cart.musement
  );

  const allBookedActivities = useAppSelector(
    (state) => state.bookings.musement
  );

  return (
    <div className="w-full my-2 text-grey-darker">
      {allMusementActivitiesInCart[date]?.map((musementEntries) => {
        const combinedMusementEntryByActivityUuid =
          getCombinedMusementEntryByActivityUuid({ musementEntries });

        const childItemUuids = getMusementItemUuidsByPersonType({
          musementEntries,
          personType: "Child",
        });

        const babyItemUuids = getMusementItemUuidsByPersonType({
          musementEntries,
          personType: "Baby",
        });

        const adultItemUuids = getMusementItemUuidsByPersonType({
          musementEntries,
          personType: "Adult",
        });

        return (
          <CartElementItineraryMusement
            key={`${combinedMusementEntryByActivityUuid.id}-${combinedMusementEntryByActivityUuid.product.scheduledDay}-${combinedMusementEntryByActivityUuid.product.scheduledTime}`}
            title={combinedMusementEntryByActivityUuid.product.title}
            image={combinedMusementEntryByActivityUuid.product.coverImageUrl}
            cartUuid={combinedMusementEntryByActivityUuid.cartUuid}
            adultItemUuids={adultItemUuids}
            childItemUuids={childItemUuids}
            babyItemUuids={babyItemUuids}
          >
            <ul className="list-reset my-2 xl:flex-grow">
              {combinedMusementEntryByActivityUuid.persons?.map(
                (
                  passenger: { firstName: string; lastName: string },
                  index: number
                ) => (
                  <li
                    key={index}
                  >{`${passenger.firstName} ${passenger.lastName}`}</li>
                )
              )}
            </ul>
          </CartElementItineraryMusement>
        );
      })}

      {allBookedActivities[date]?.map((musementEntries) => {
        const combinedMusementEntryByActivityUuid =
          getCombinedMusementEntryByActivityUuid({ musementEntries });

        const allItemUuids = getMusementItemUuidsByPersonType({
          musementEntries,
          personType: "All",
        });

        const allItemIds = getMusementItemIdsByPersonType({
          musementEntries,
          personType: "All",
        });

        return (
          <CartElementPaidActivityMusement
            key={`${combinedMusementEntryByActivityUuid.id}-${combinedMusementEntryByActivityUuid.product.scheduledDay}-${combinedMusementEntryByActivityUuid.product.scheduledTime}`}
            title={combinedMusementEntryByActivityUuid.product.title}
            imageReference={
              combinedMusementEntryByActivityUuid.product.coverImageUrl
            }
            itemUuid={combinedMusementEntryByActivityUuid.itemUuid}
            multipleItemUuids={allItemUuids?.join(",")}
            transactionUuid={
              combinedMusementEntryByActivityUuid?.transactionUuid
            }
            itemId={combinedMusementEntryByActivityUuid.id}
            multipleItemIds={allItemIds?.join(",")}
            // linkTo={`/musement/excursion/${combinedMusementEntryByActivityUuid.itineraryId}/${combinedMusementEntryByActivityUuid.product.activityUuid}`}
            tooltipText={`Price paid for this<br/>excursion (total) £${combinedMusementEntryByActivityUuid.totalPrice}`}
            tooltipId={`${combinedMusementEntryByActivityUuid.id}-${combinedMusementEntryByActivityUuid.product.scheduledDay}-${combinedMusementEntryByActivityUuid.product.scheduledTime}`}
            totalPrice={combinedMusementEntryByActivityUuid.totalPrice}
            passengers={combinedMusementEntryByActivityUuid.persons}
            isBookedByAgency={combinedMusementEntryByActivityUuid.agency}
          >
            <ul className="list-reset my-2 md:flex-grow">
              {combinedMusementEntryByActivityUuid.persons.map(
                (passenger: IExcursionPassenger, index: number) => (
                  <li
                    key={index}
                  >{`${passenger.firstName} ${passenger.lastName}`}</li>
                )
              )}
            </ul>
          </CartElementPaidActivityMusement>
        );
      })}
    </div>
  );
};

export default CartElementsMusement;
