import { ChangeEvent, FC, useState } from "react";
import Modal from "../Modal/Modal";
import SvgIcon from "../../atoms/SvgIcon";
import { _isAfter, convertDMYtoYMD, formatPortDate } from "utils/dateTimeUtils";
import Checkbox from "../../Checkbox";
import TextInputWithLabel from "../../TextInputWithLabel";
import ModalFooter from "../ModalFooter";
import SelectDropdown from "../../SelectDropdown";
import { IMxpRestaurant } from "interfaces/MXP/IMxpRestaurant";
import { useGetRestaurantsSlots } from "modules/mxp/dining/hooks/useGetRestaurantsSlots";
import MxpAvailabilityOption from "modules/mxp/dining/MxpAvailabilityOption";
import { IMxpAvailabilitySlot } from "interfaces/MXP/IMxpAvailabilitySlot";
import Loader from "components/atoms/Loader";
import { usePostAddRestaurantToPlanner } from "modules/mxp/dining/hooks/usePostAddRestaurantToPlanner";
import Price from "components/Price/Price";

const LATEST_TIME_FOR_CHILD_DINING_BOOKING = "19:00";

type Props = {
  restaurant: IMxpRestaurant;
  date: string;
  onError: (value: string) => void;
  onSuccess: () => void;
  closeModal: () => void;
};

type DietaryOptionKeys =
  | "dairy"
  | "vegan"
  | "gluten"
  | "nut"
  | "shellfish"
  | "lactose"
  | "other";

type DietaryOptions = {
  [key in DietaryOptionKeys]: string;
};

type SpecialRequirements = {
  [key in DietaryOptionKeys]?: boolean;
};

const minimumNumberOfAdults = 1;
const maximumNumberOfAdults = 4;

const minimumNumberOfChildren = 0;
const maximumNumberOfChildren = 4;

const initialNrAdultsSelected = 2;
const initialNrChildrenSelected = 0;

const dietaryOptions: DietaryOptions = {
  dairy: "Dairy free",
  vegan: "Vegan",
  gluten: "Gluten free",
  nut: "Nut allergy",
  shellfish: "Shellfish allergy",
  lactose: "Lactose intolerant",
  other: "Other",
};

const range = (start: number, stop: number) =>
  Array.from({ length: stop - start + 1 }, (_, i) => start + i);

const MxpRestaurantAvailabilityModal: FC<Props> = ({
  restaurant,
  date,
  onError,
  onSuccess,
  closeModal,
}) => {
  const [nrAdultsSelected, setNrAdultsSelected] = useState(
    initialNrAdultsSelected
  );
  const [nrChildrenSelected, setNrChildrenSelected] = useState(
    initialNrChildrenSelected
  );

  const [selectedAvailability, setSelectedAvailability] =
    useState<IMxpAvailabilitySlot | null>(null);

  const { data, isFetching: isLoading } = useGetRestaurantsSlots({
    venueId: restaurant.venueId,
    date: convertDMYtoYMD(date),
    numberOfPeople: nrAdultsSelected + nrChildrenSelected,
  });

  // special rule for The Exchange events only, refer to CRUISE-503 task
  const THE_EXCHANGE_RESTAURANT = "The Exchange";
  const isExchangeRestaurant = restaurant.venueName.includes(
    THE_EXCHANGE_RESTAURANT
  );
  const adultsDropdownLabel = isExchangeRestaurant
    ? "Adults (18+)"
    : "Adults (13+)";

  const initialSpecialRequirements: SpecialRequirements = {};
  let initialOtherAllergyText = "";

  const {
    postAddRestaurantToPlanner,
    postAddRestaurantToPlannerLoading,
    postAddRestaurantToPlannerIsSuccess,
    postAddRestaurantToPlannerError,
  } = usePostAddRestaurantToPlanner();

  const [isDietaryFormOpen, setIsDietaryFormOpen] = useState(false);

  const [otherAllergyText, setOtherAllergyText] = useState(
    initialOtherAllergyText
  );
  const [otherAllergyTextError, setOtherAllergyTextError] = useState("");
  const [specialRequirements, setSpecialRequirements] = useState(
    initialSpecialRequirements
  );
  const handleDropdownChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.id === "adults-dropdown") {
      setNrAdultsSelected(parseInt(e.target.value));
    } else {
      setNrChildrenSelected(parseInt(e.target.value));
    }

    setSelectedAvailability(null);
  };

  const handleSlotSelect = (availability: IMxpAvailabilitySlot) => {
    setSelectedAvailability(availability);
  };

  const toggleDietaryForm = () => {
    setIsDietaryFormOpen(!isDietaryFormOpen);
  };

  const handleCheckboxChange = (key: DietaryOptionKeys) => {
    const updatedSpecialRequirements = { ...specialRequirements };
    updatedSpecialRequirements[key] = !updatedSpecialRequirements[key];
    setSpecialRequirements(updatedSpecialRequirements);
  };

  const handleAllergyTextChange = (value: string) => {
    if (value.length > 30) {
      setOtherAllergyTextError("Please limit your response to 30 characters");
      return;
    }

    setOtherAllergyText(value);
    setOtherAllergyTextError("");
  };

  const addToCart = async () => {
    if (selectedAvailability) {
      await postAddRestaurantToPlanner({
        vacancy: selectedAvailability,
      });
    }
  };

  if (postAddRestaurantToPlannerIsSuccess) {
    onSuccess();
  }

  if (postAddRestaurantToPlannerError) {
    onError(postAddRestaurantToPlannerError?.message);
  }

  return (
    <Modal show={true} onClose={closeModal} title="Restaurant reservation">
      <>
        <div className="px-4 pt-2 text-blue-dark">
          <div className="text-lg border-b pb-2">
            {restaurant.venueName?.toUpperCase()}
          </div>
          <div className="text-grey-darker text-lg my-4">
            {formatPortDate(date)}
          </div>
          <div className="my-4">How many people?</div>
          <div className="my-4 flex items-end">
            <SelectDropdown
              classes="mr-4 w-1/2 md:w-40"
              selectId="adults-dropdown"
              dataTestIdName="adults-dropdown"
              label={adultsDropdownLabel}
              disabled={isLoading || postAddRestaurantToPlannerLoading}
              error={false}
              options={range(minimumNumberOfAdults, maximumNumberOfAdults)}
              handleChange={handleDropdownChange}
              value={nrAdultsSelected}
            />

            {!isExchangeRestaurant && (
              <SelectDropdown
                classes="w-1/2 md:w-40"
                selectId="children-dropdown"
                dataTestIdName="children-dropdown"
                label="Children (2-12 years)"
                disabled={isLoading || postAddRestaurantToPlannerLoading}
                error={false}
                options={range(
                  minimumNumberOfChildren,
                  maximumNumberOfChildren
                )}
                handleChange={handleDropdownChange}
                value={nrChildrenSelected}
              />
            )}
          </div>

          {nrChildrenSelected > 0 && (
            <div className="bg-grey-lighter px-4 py-3">
              Family-friendly dining is between 18:00 and 19:00.
            </div>
          )}

          {/* loading availabilities */}
          {isLoading && <Loader text="Searching for available time slots..." />}

          {/* loading availabilities */}
          {postAddRestaurantToPlannerLoading && (
            <Loader text="Adding to planner..." />
          )}

          {/* no error and availabilities loaded*/}
          {!isLoading && !postAddRestaurantToPlannerLoading && (
            <div>
              <div className="my-4">What time would you like your table?</div>

              {data?.length === 0 && (
                <div className="bg-grey-lighter px-4 py-3">
                  Unfortunately, we don’t have any tables available for your
                  dining party to book at this time. Please enquire about your
                  dining options onboard.
                </div>
              )}
              <div className="mt-4 md:flex md:flex-wrap md:-mx-2">
                {data
                  ?.sort((a, b) => {
                    return a.AvailableTime.localeCompare(b.AvailableTime);
                  })
                  .map((availability) => (
                    <MxpAvailabilityOption
                      key={availability.VacancyId}
                      availability={availability}
                      isSelected={
                        selectedAvailability?.VacancyId ===
                        availability.VacancyId
                      }
                      isDisabled={
                        nrChildrenSelected > 0 &&
                        _isAfter(
                          availability.AvailableTime,
                          LATEST_TIME_FOR_CHILD_DINING_BOOKING
                        )
                      }
                      onClick={() => handleSlotSelect(availability)}
                    />
                  ))}
              </div>
            </div>
          )}

          {/* Dietary restrictions / allergies */}
          {!isLoading &&
            !postAddRestaurantToPlannerLoading &&
            data?.length > 0 && (
              <div>
                {isDietaryFormOpen && (
                  <div className="flex flex-wrap text-grey-dark">
                    {Object.keys(dietaryOptions).map((key) => {
                      const dietaryKey = key as DietaryOptionKeys;
                      return (
                        <div key={key} className="w-1/2 sm:w-1/3 mt-4">
                          <Checkbox
                            checked={!!specialRequirements[dietaryKey]} // Ensure boolean type
                            value={dietaryOptions[dietaryKey]}
                            handleCheckboxChange={() =>
                              handleCheckboxChange(dietaryKey)
                            }
                          >
                            {dietaryOptions[dietaryKey]}
                          </Checkbox>
                        </div>
                      );
                    })}
                  </div>
                )}
                {isDietaryFormOpen && specialRequirements?.other && (
                  <div>
                    <TextInputWithLabel
                      classNames="w-full mt-2"
                      labelClassNames="text-blue-dark leading-loose"
                      label="Please provide further details about your allergy:"
                      type="text"
                      value={otherAllergyText}
                      _onChange={(value: string) =>
                        handleAllergyTextChange(value)
                      }
                      placeholder="max 30 characters"
                      errorMessage={otherAllergyTextError}
                    />
                  </div>
                )}
                <div
                  className="my-4 text-blue cursor-pointer"
                  onClick={toggleDietaryForm}
                >
                  Add a dietary/allergy request{" "}
                  <SvgIcon
                    name={isDietaryFormOpen ? "chevron-up" : "chevron-down"}
                    className="w-6 h-6 inline ml-2"
                  />
                </div>

                {/* total cost summary */}
                <div className="border-b border-t py-2 flex justify-between items-center">
                  <div className="uppercase font-bold">Total cost</div>
                  <Price
                    price={
                      selectedAvailability
                        ? selectedAvailability?.totalAmount?.toString()
                        : "0.00"
                    }
                  />
                </div>
              </div>
            )}
        </div>

        <ModalFooter
          onCancel={closeModal}
          actionDisabled={
            selectedAvailability === null ||
            postAddRestaurantToPlannerLoading ||
            isLoading
          }
          actionButtonText="Add To Planner"
          onActionClick={addToCart}
        />
      </>
    </Modal>
  );
};

export default MxpRestaurantAvailabilityModal;
